import { DataGridPro, GridApiPro, GridCallbackDetails, GridEventListener, GridFilterModel, GridPagination } from '@mui/x-data-grid-pro'
import { TablePaginationProps } from '@mui/base'
import MuiPagination from '@mui/material/Pagination'
import { JSXElementConstructor } from 'react'
import classes from './MuiTable.module.css'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

const MuiTable: React.FC<MuiTablePropTypes> = (props) => {
  const initialState = {
    ...(props?.page !== undefined ? {pagination: { paginationModel: { pageSize: 10 } }} : {}),
    ...(props?.filterModel !== undefined ? {filter: {filterModel: props?.filterModel}} : {}),
  }
  const slots = {
    noRowsOverlay: CustomNoRowsOverlay,
    noResultsOverlay: () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        Local filter returns no result
      </Stack>
    ),
    ...(props?.page !== undefined ? {pagination: CustomPagination} : {}),
    ...(!!props?.toolbar ? {toolbar: props.toolbar} : {}),
  }
  const slotProps = {...(props?.page !== undefined ? {pagination : {onPageChange: props?.onPageChange, page: props?.page, count: props?.count}} : {})}

  return (
    <>
      <DataGridPro
        sx={{
          '.MuiDataGrid-columnHeaderTitle': { 
            color: '#101012',
            fontSize: '14px',
            fontWeight: '500 !important',
          }
        }}
        rows={props?.rows}
        columns={props?.columns}
        initialState={initialState}
        pagination={props?.page !== undefined}
        slots={slots}
        slotProps={slotProps}
        pageSizeOptions={props?.pageSizeOptions
          ? props.pageSizeOptions
          : props?.page !== undefined ? [10] : undefined}
        className={`
          ${classes.wrapper}
          ${!props?.loading && !props?.rows?.length && 'emptyTableWrapper'}
          ${!!props?.toolbar && 'customToolbarTable'}
        `}
        apiRef={props?.apiRef}
        loading={props?.loading}
        disableRowSelectionOnClick
        sortingMode={props.sortingMode}
        onSortModelChange={props.onSortModelChange}
        getRowId={props?.getRowId}
        getRowHeight={props?.getRowHeight}
        sortModel={props.sortModel}
        filterModel={props.filterModel}
        onFilterModelChange={props.onFilterModelChange}
        rowReordering={!!props.onRowOrderChange}
        onRowOrderChange={props.onRowOrderChange}
        onCellClick={props.onCellClick}
      />
    </>
  )
}

const CustomPagination: JSXElementConstructor<any> | null | undefined = (props: any) => {
  return <GridPagination ActionsComponent={Pagination} {...props} className={classes.paginationWrapper} />
}

function Pagination({
  page,
  onPageChange,
  count
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'count'>) {
  const defaultPageSize = 10
  const pageCount = Math.ceil(count / defaultPageSize)
  
  return (
    <MuiPagination
      color='primary'
      className={classes.pageNumbers}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1)
      }}
      shape='rounded' 
    />
  )
}
function CustomNoRowsOverlay() {
  const { t } = useTranslation(['app'])

  return (
    <div className={classes.emptyTable} style={{height: '300px !important'}}>
      <div>{t('warnings.nothingFound')}</div>
    </div>
  )
}

interface MuiTablePropTypes {
  rows: any[]
  columns: any[]
  page?: number
  count?: number
  onPageChange?: (event: any, newPage: number) => void
  pageSizeOptions?: number[]
  apiRef?: React.MutableRefObject<GridApiPro>
  loading?: boolean
  sortingMode?: any
  onSortModelChange?: any
  sortModel?: any
  toolbar?: (props: any) => JSX.Element
  getRowId?: (row: any) => number
  getRowHeight?: (row: any) => number
  filterModel?: GridFilterModel
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void
  onRowOrderChange?: GridEventListener<'rowOrderChange'>
  onCellClick?: (val: {field: string, row: any}) => void
}

export default MuiTable
