import {Spin, Tabs} from 'antd'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import classes from './UserForm.module.css'
import GoBackLink from '../../common/GoBackLink/GoBackLink'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {
  GetUserBillingSettingsThunk,
  GetUserByIdThunk,
  GetUserTokensThunk,
  selectSelectedUserData,
  selectUserDetailsActiveTabKey,
  setUserDetailsActiveTabKey
} from '../../../store/userReducer'
import {UserDetailsActiveTabKeyType} from '../../../types/userTypes'
import GeneralTab from './GeneralTab/GeneralTab'
import Billing from '../../Billing/Billing'
import { useTranslation } from 'react-i18next'

const UserForm: React.FC<{ isEditing: boolean }> = ({isEditing}) => {
  const { t } = useTranslation(['pages', 'app'])
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const selectedUserData = useAppSelector(selectSelectedUserData)
  const activeTabKey = useAppSelector(selectUserDetailsActiveTabKey)

  const [isUserDataLoading, setIsUserDataLoading] = useState(false)
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [isBackLinkConfirmModalShown, setIsBackLinkConfirmModalShown] = useState(false)

  useEffect(() => {
    //pathname example: /users/edit/6
    const id = pathname.split('/users/edit/')[1]
    if (isEditing && selectedUserData?.user_id !== +id) {
      setIsUserDataLoading(true)
      Promise.all([
        dispatch(GetUserByIdThunk(+id)),
        dispatch(GetUserTokensThunk(+id)),
        dispatch(GetUserBillingSettingsThunk({userId: +id, isCurrentUser: false})),
      ])
        .then(() => setIsUserDataLoading(false))
    }
  }, [dispatch, selectedUserData, isEditing, pathname])

  const onTabChange = (key: UserDetailsActiveTabKeyType) => {
    dispatch(setUserDetailsActiveTabKey(key))
  }

  const personDataTabs = [
    {
      key: 'general',
      label: t('userDetails.general.tabName'),
      children: <GeneralTab
        isEditing={isEditing}
        hasDataChanged={hasDataChanged}
        setHasDataChanged={(changed) => setHasDataChanged(changed)}
        isBackLinkConfirmModalShown={isBackLinkConfirmModalShown}
        setIsBackLinkConfirmModalShown={(isOpen: boolean) => setIsBackLinkConfirmModalShown(isOpen)}
      />,
    },
    ...(selectedUserData?.billing_info !== null ? [{
      key: 'billing',
      label: t('userDetails.billing.tabName'),
      children: <Billing isCurrentUserBalance={false} noBalance={!isEditing}/>
    }] : []),
  ]

  if (isUserDataLoading) {
    return <Spin style={{width: '100%'}}/>
  }

  return (
    <div className={classes.wrapper}>
      <GoBackLink
        link='/users'
        text={t('userDetails.backBtn')}
        showConfirmModal={hasDataChanged ? () => setIsBackLinkConfirmModalShown(true) : undefined}
      />
      <div className={classes.pageTitle}>
        <h1 className='pageTitle'>
          {isEditing ? selectedUserData?.first_name + ' ' + selectedUserData?.last_name : t('userDetails.title')}
        </h1>
      </div>
      <Tabs
        items={personDataTabs}
        activeKey={activeTabKey}
        onChange={(key) => onTabChange(key as UserDetailsActiveTabKeyType)}
      />
    </div>
  )
}

export default UserForm
