import { Button, DatePicker, Spin } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { useGridApiRef, GridSortModel } from '@mui/x-data-grid-pro'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classes from './PersonList.module.css'
import { useAppDispatch, useAppSelector, usePrevious } from '../../app/hooks'
import { DeletePersonThunk, EditPersonThunk, GetPersonListThunk, getPersonGeneralData, selectPersonList, selectPersonListManualParams, selectPersonListRequestParams, setPersonDetailsActiveTabKey, setPersonListManualParams, setSelectedPersonData } from '../../store/personReducer'
import { PersonStatusType, PersonType } from '../../types/personTypes'
// import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'
import { ReactComponent as EditIcon } from './../../img/icons/edit.svg'
import { ReactComponent as DeleteIcon } from './../../img/icons/delete.svg'
import { ReactComponent as ActivateIcon } from './../../img/icons/activate.svg'
import { ReactComponent as DeactivateIcon } from './../../img/icons/deactivate.svg'
import { ReactComponent as ReportIcon } from './../../img/icons/report.svg'
import { ReactComponent as SessionIcon } from './../../img/icons/session.svg'
import { ReactComponent as AddSessionIcon } from './../../img/icons/addSession.svg'
import MuiTable from '../common/MuiTable/MuiTable'
import RadioGroup from '../common/RadioGroup/RadioGroup'
import { selectBillingSettings, selectIsSuperUser, selectIsUser, selectSuperUserCurrentUser, selectUserData } from '../../store/currentUserReducer'
import { GetSessionsExcelThunk, setSessionData } from '../../store/sessionReducer'
import { setRequestData } from '../../store/requestReducer'
import ConfirmationModal from '../common/ConfirmationModal/ConfirmationModal'
import TooltipHint from '../common/TooltipHint/TooltipHint'
import {GetUserBillingSettingsThunk, selectUserBillingSettings} from '../../store/userReducer'
import InfoModal from '../common/InfoModal/InfoModal'
import SuperUserWarningMessage from '../common/SuperUserWarningMessage/SuperUserWarningMessage'
import { getUIPermission } from '../../store/appStatusReducer'
import ExportSessionsBtn from '../common/ExportSessionsBtn/ExportSessionsBtn'
import { getDisplayData } from '../../helpers/filtersHelper'
import NewUserHintModal from './NewUserHintModal/NewUserHintModal'

const PersonList = () => {
  const { t } = useTranslation(['pages', 'app'])
  const dispatch = useAppDispatch()
  const apiRef = useGridApiRef()
  const navigate = useNavigate()
  const personListRequestParams = useAppSelector(selectPersonListRequestParams)
  const personListManualParams = useAppSelector(selectPersonListManualParams)
  const personList = useAppSelector(selectPersonList)
  const userData = useAppSelector(selectUserData)
  const isUser = useAppSelector(selectIsUser)
  const isSuperUser = useAppSelector(selectIsSuperUser)
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)
  const userBillingSettings = useAppSelector(selectUserBillingSettings)
  const currentUserBillingSettings = useAppSelector(selectBillingSettings)
  const allowAddOwnPatient = useAppSelector(getUIPermission('Button.Patients.Own.Add')) && !superUserCurrentUser?.user_id
  const allowAddPatient = useAppSelector(getUIPermission('Button.Patients.Add')) && superUserCurrentUser?.user_id
  const billingSettings = superUserCurrentUser?.user_id ? userBillingSettings : currentUserBillingSettings

  const [confirmationModalData, setConfirmationModalData] = useState<null | {type: 'delete' | 'deactivate' | 'activate', person: PersonType}>(null)
  const [dataForDisplay, setDataForDisplay] = useState<{data: PersonType[] | null, totalCount: number}>({data: null, totalCount: 0})
  const [noCreditsModalOpened, setNoCreditsModalOpened] = useState(false)
  const [isNoInstancesWarningData, setIsNoInstancesWarningData] = useState<{id: null | number, hasInstances: boolean}>({id: null, hasInstances: false})  

  const personStatusOptions: {label: string, value: PersonStatusType}[] = [
    {label: t('patientList.toolbar.listFilterOptions.active'), value: 'ACTIVE'},
    {label: t('patientList.toolbar.listFilterOptions.inactive'), value: 'INACTIVE'},
    {label: t('patientList.toolbar.listFilterOptions.all'), value: 'ALL'},
  ]

  const getDataForDisplay = () => {
    let dataForPagination = personListManualParams.activeStatus === 'ALL'
      ? personList
      : personList?.filter(person => personListManualParams.activeStatus === 'ACTIVE' ? !!person.is_active : !person.is_active) 
    const updatedData = getDisplayData<PersonType>(dataForPagination || [], personListManualParams)
    setDataForDisplay(updatedData)
  }

  const addSession = async(patientId: number, hasInstances: boolean, patientData?: PersonType) => {
    if (!!hasInstances && !!patientData) {
      dispatch(setSelectedPersonData(patientData))
      navigate(`/patients/${patientId}/sessions/add`)
    } else if (!hasInstances && !isNoInstancesWarningData?.id) {
      setIsNoInstancesWarningData({id: patientId, hasInstances})
    } else {
      !!isNoInstancesWarningData && setIsNoInstancesWarningData({id: null, hasInstances: false})
      navigate(`/patients/edit/${patientId}`)
    }
  }

  const columns = [
    {
      field: 'name',
      headerName: t('patientList.table.columnTitles.pseudonym'),
      flex: 1,
      minWidth: 120,
      filterable: true,
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <div style={{fontSize: '14px', fontWeight: 500}}>
          {params.row.name}
        </div>
      )
    },
    ...(personListManualParams?.activeStatus === 'ALL'
      ? [{
        field: 'is_active',
        headerName: t('patientList.table.columnTitles.status'),
        flex: 0.5,
        minWidth: 100,
        filterable: false,
        cellClassName: 'tableSecondaryTextStyle',
        renderCell: (params: GridRenderCellParams<any, Date>) => (
          <div className={`${classes.status} ${!!params.row.is_active ? classes.active : ''}`}>
            {params.row.is_active === true
              ? 'Active'
              : 'Inactive'
            }
          </div>
        )
      }]
      : []
    ),
    {
      field: 'last_session_time',
      headerName: t('patientList.table.columnTitles.lastVisit'),
      flex: 0.5,
      minWidth: 100,
      filterable: true,
      cellClassName: 'tableSecondaryTextStyle',
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <div style={{fontSize: '14px'}}>
          {params.row.last_session_time
            ? dayjs(params.row.last_session_time).format('DD.MM.YYYY')
            : ''
          }
        </div>
      )
    },
    {
      field: 'presenting_symptoms',
      headerName: t('patientList.table.columnTitles.presentingSymptoms'),
      flex: 1.2,
      minWidth: 100,
      filterable: true,
      cellClassName: 'tableSecondaryTextStyle'
    },
    {
      field: 'session_count',
      headerName: t('patientList.table.columnTitles.sessions'),
      flex: 0.5,
      minWidth: 100,
      filterable: true,
      cellClassName: 'tableSecondaryTextStyle',
    },
    {
      field: 'Actions',
      headerName: '',
      sortable: false,
      filterable: false,
      flex: 0.4,
      minWidth: 260,
      renderCell: (params: GridRenderCellParams<PersonType, Date>) => (
        <div className={classes.tableActions}>
          {params?.row?.is_add_session &&
            <TooltipHint title={t('patientList.table.actionHints.addSessions')}>
              <AddSessionIcon
                className={classes.addSessionIcon}
                onClick={() => addSession(params.row.id, !!params.row?.instance_list?.length, params?.row)}
              />
            </TooltipHint>
          }
          {params?.row?.is_get_sessions &&
            <TooltipHint title={t('patientList.table.actionHints.sessions')}>
              <SessionIcon
                onClick={() => {
                  dispatch(setPersonDetailsActiveTabKey('sessions'))
                  navigate(`/patients/edit/${params.row.id}`)
                }}
              />
            </TooltipHint>
          }

          {params?.row?.is_get_reports &&
            <TooltipHint title={t('patientList.table.actionHints.reports')}>
              <ReportIcon
                onClick={() => {
                  dispatch(setPersonDetailsActiveTabKey('reports'))
                  navigate(`/patients/edit/${params.row.id}`)
                }}
              />
            </TooltipHint>
          }

          {params?.row?.is_btn_activate &&
            <TooltipHint title={params.row.is_active ? t('patientList.table.actionHints.deactivate') : t('patientList.table.actionHints.activate')}>
              {params.row.is_active
                ? (
                  <DeactivateIcon
                    style={{marginBottom: '3px'}}
                    onClick={() => setConfirmationModalData({type: 'deactivate', person: params.row})}
                  />
                ) : (
                  <ActivateIcon
                    style={{marginBottom: '3px'}}
                    onClick={() => setConfirmationModalData({type: 'activate', person: params.row})}
                  />
                )
              }
            </TooltipHint>
          }
          
          {params?.row?.is_btn_delete &&
            <TooltipHint title={t('patientList.table.actionHints.delete')}>
              <DeleteIcon onClick={() => setConfirmationModalData({type: 'delete', person: params.row})}/>
            </TooltipHint>
          }

          {params?.row?.is_btn_edit &&
            <TooltipHint title={t('patientList.table.actionHints.viewGeneralInfo')}>
              <Link to={`/patients/edit/${params.row.id}`} style={{marginBottom: '-5px'}}>
                <EditIcon />
              </Link>
            </TooltipHint>
          }
        </div>
      )
    },
  ]

  const prevSuperUserSelectedUser = usePrevious(superUserCurrentUser?.user_id)

  useEffect(() => {
    const superUserCurrentUserHasChanged = superUserCurrentUser?.user_id !== prevSuperUserSelectedUser || (!!personList?.length && !superUserCurrentUser?.user_id && personList[0]?.user?.user_id !== userData?.user_id)
    if ((isUser && userData.user_id && personList === null) || superUserCurrentUserHasChanged) {
      dispatch(GetPersonListThunk({
        ...personListRequestParams,
        person_request: {...personListRequestParams.person_request, user_id: superUserCurrentUser?.user_id || userData.user_id}
      }))
    }
    // eslint-disable-next-line
  }, [dispatch, userData, personList, personListRequestParams, superUserCurrentUser, isUser])

  useEffect(() => {
    getDataForDisplay()
    // eslint-disable-next-line
  }, [personListManualParams, personList])

  useEffect(() => {
    dispatch(setSessionData(null))
    dispatch(setRequestData(null))
    dispatch(setPersonDetailsActiveTabKey('general'))
    dispatch(setSelectedPersonData(null))
  }, [dispatch])

  const handleSortModelChange = (sortModel: GridSortModel) => {
    dispatch(setPersonListManualParams({
      ...personListManualParams,
      page: 1,
      sort: sortModel
    }))
  }

  useEffect(() => {
    if (!!superUserCurrentUser?.user_id) {
      dispatch(GetUserBillingSettingsThunk({
        userId: superUserCurrentUser?.user_id,
        isCurrentUser: false
      }))
    }
  }, [dispatch, userData, superUserCurrentUser])

  const onAddNewPatient = () => {
    setNoCreditsModalOpened(true)
  }

  return (
    <>
      <div className={classes.titleWrapper}>
        <h1 className='pageTitle'>
          {t('patientList.title')}
        </h1>
        {(allowAddPatient || allowAddOwnPatient) &&
          <>
            {billingSettings?.available_person === 0 ? (
              <Button type='primary' onClick={onAddNewPatient}>
                + {t('patientList.toolbar.addPatientBtn')}
              </Button>
            ) : (
              <Link to='/patients/add'>
                <Button type='primary'>
                  + {t('patientList.toolbar.addPatientBtn')}
                </Button>
              </Link>
            )}
          </>
        }
      </div>
      <h2 className='pageDescription'>
        {t('patientList.description')}
      </h2>

      {dataForDisplay?.data === null &&
        <Spin style={{width: '100%'}}/>
      }

      {isSuperUser && !isUser && !superUserCurrentUser?.user_id &&
        <SuperUserWarningMessage />
      }

      <NewUserHintModal isOpen={!!userData?.is_first_login} />

      {dataForDisplay?.data !== null && (isUser || superUserCurrentUser?.user_id) &&
        <>
          <RadioGroup
            options={personStatusOptions}
            onChange={(value) => {
              dispatch(setPersonListManualParams({
                ...personListManualParams,
                page: 1,
                activeStatus: value as PersonStatusType,
              }))
            }}
            value={personListManualParams.activeStatus}
          />
          
          {/* <Input
            placeholder='Search'
            prefix={<SearchIcon />}
            style={{width: '400px', marginBottom: '24px'}}
            onChange={(e) => onSearch(e.target.value)}
          /> */}

          <MuiTable
            columns={columns}
            rows={dataForDisplay?.data}
            page={personListManualParams.page - 1}
            count={dataForDisplay.totalCount}
            onPageChange={(_, page) => {
              dispatch(setPersonListManualParams({
                ...personListManualParams,
                page: page + 1,
              }))
            }}
            apiRef={apiRef}
            sortingMode='server'
            onSortModelChange={handleSortModelChange}
            filterModel={personListManualParams.filters}
            sortModel={personListManualParams?.sort?.length ? [{
              field: personListManualParams?.sort?.[0]?.field,
              sort: personListManualParams?.sort?.[0]?.sort?.toLowerCase()
            }] : []}
            onFilterModelChange={(model) => {
              dispatch(setPersonListManualParams({
                ...personListManualParams,
                page: 1,
                filters: model
              }))
            }}
          />
        </>
      }
      <ExportSessions
        isActivePatients={personListManualParams.activeStatus === 'ALL'
          ? null
          : personListManualParams.activeStatus === 'ACTIVE'
        }
      />
      <ConfirmationModal
        open={!!confirmationModalData?.person?.id}
        title={`${t(`patientList.table.actionConfirmation.${confirmationModalData?.type!}`, {name: confirmationModalData?.person?.name || ''})}`}
        description={t(`warnings.${confirmationModalData?.type!}`, {ns: 'app', itemName: t('patient', {ns: 'app'})})}
        onClose={() => setConfirmationModalData(null)}
        onConfirm={() => {
          if (confirmationModalData?.type === 'delete') {
            return dispatch(DeletePersonThunk(confirmationModalData?.person?.id!))
              .then(() => setConfirmationModalData(null))
          } else {
            return dispatch(EditPersonThunk({
              personId: confirmationModalData?.person?.id!,
              person: {...getPersonGeneralData(confirmationModalData?.person as PersonType), user_id: userData.user_id, is_active: !confirmationModalData?.person?.is_active}
            })).then(() => setConfirmationModalData(null))
          }
        }}
        type={confirmationModalData?.type === 'activate' ? 'success' : 'delete'}
        confirmText={t(`actions.${confirmationModalData?.type!}`, {ns: 'app'})}
      />
      {
        noCreditsModalOpened
          &&
          <InfoModal
            title={t('warnings.newPatientNotAvailable.title', {ns: 'app'})}
            text={t('warnings.newPatientNotAvailable.description', {ns: 'app', patientTotalCount: billingSettings?.total_person?.toString()})}
            closeModal={() => setNoCreditsModalOpened(false)}
          />
      }
      <ConfirmationModal
        open={!!isNoInstancesWarningData.id}
        title={t('warnings.noInstancesForSession.title', {ns: 'app'})}
        description={t('warnings.noInstancesForSession.description', {ns: 'app'})}
        onClose={() => setIsNoInstancesWarningData({id: null, hasInstances: false})}
        onConfirm={() => addSession(isNoInstancesWarningData.id as number, !!isNoInstancesWarningData.hasInstances)}
        type={'warning'}
        confirmText={t('actions.add', {ns: 'app'})}
      />
    </>
  )
}

const ExportSessions: React.FC<{isActivePatients: boolean | null}> = ({isActivePatients}) => {
  const { t } = useTranslation('pages')
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)

  const [dates, setDates] = useState<[Dayjs, Dayjs]>([dayjs().startOf('month'), dayjs()])
  const [isExporting, setIsExporting] = useState(false)

  const exportPatientsData = (instances: 'all' | 'sessions') => {
    setIsExporting(true)
    dispatch(GetSessionsExcelThunk({
      date_from: dates[0].format('YYYY-MM-DDTHH:mm:ss[Z]'),
      date_till: dates[1].format('YYYY-MM-DDTHH:mm:ss[Z]'),
      patient_id: null,
      user_id: userData.user_id,
      with_all_instances: instances === 'all',
      is_patient_active: isActivePatients
    }))
      .then(() => setIsExporting(false))
  }
  return (
    <div className={classes.exportSessionsWrapper}>
      <div>
        {t('patientList.exportToolbar.title')}
      </div>
      <div style={{display: 'flex'}}>
        <DatePicker.RangePicker
          value={dates}
          allowClear={false}
          style={{maxWidth: '330px'}}
          onChange={val => {
            setDates([val?.[0], val?.[1]?.endOf('day')] as [Dayjs, Dayjs])
          }}
          disabled={isExporting}
        />
        <ExportSessionsBtn
          isLoading={isExporting}
          onExport={(instances: 'all' | 'sessions') => {exportPatientsData(instances)}}
        />
      </div>
    </div>
  )
}

export default PersonList
