import { Button, Spin, Tabs } from 'antd'
import { useLocation } from 'react-router'
import { useEffect, useState } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GoBackLink from '../../common/GoBackLink/GoBackLink'
import classes from './PersonForm.module.css'
import GeneralTab from './GeneralTab/GeneralTab'
import SessionsTab from './SessionsTab/SessionsTab'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetPersonByIdThunk, selectPersonDetailsActiveTabKey, selectSelectedPersonData, setPersonDetailsActiveTabKey } from '../../../store/personReducer'
import { PersonDetailsActiveTabKeyType } from '../../../types/personTypes'
import ReportsTab from './ReportsTab/ReportsTab'
import { GetSessionsByPersonIdThunk, selectSessionList, selectSessionListRequestParams, selectSessionListTotalCount, setSessionData, setSessionList } from '../../../store/sessionReducer'
import { GetRequestListByPersonIdThunk, selectRequestList, selectRequestListRequestParams, selectRequestListTotalCount, setRequestData, setRequestList } from '../../../store/requestReducer'
import {GetUserBillingSettingsThunk, selectUserBillingSettings} from "../../../store/userReducer"
import {selectBillingSettings, selectIsSuperUser, selectIsUser, selectSuperUserCurrentUser, selectUserData} from "../../../store/currentUserReducer"
import InfoModal from "../../common/InfoModal/InfoModal"
import SuperUserWarningMessage from '../../common/SuperUserWarningMessage/SuperUserWarningMessage'
import ConfirmationModal from '../../common/ConfirmationModal/ConfirmationModal'
import { UserBillingSettingsType } from '../../../types/billingTypes'

const PersonForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const { t } = useTranslation(['pages', 'app'])
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const selectedPersonData = useAppSelector(selectSelectedPersonData)
  const activeTabKey = useAppSelector(selectPersonDetailsActiveTabKey)
  const sessionList = useAppSelector(selectSessionList)
  const sessionListTotalCount = useAppSelector(selectSessionListTotalCount)
  const requestList = useAppSelector(selectRequestList)
  const requestListTotalCount = useAppSelector(selectRequestListTotalCount)
  const requestListRequestParams = useAppSelector(selectRequestListRequestParams)
  const sessionListRequestParams = useAppSelector(selectSessionListRequestParams)
  const userData = useAppSelector(selectUserData)
  const isUser = useAppSelector(selectIsUser)
  const isSuperUser = useAppSelector(selectIsSuperUser)
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)
  const userBillingSettings = useAppSelector(selectUserBillingSettings)
  const currentUserBillingSettings = useAppSelector(selectBillingSettings)
  const billingSettings = superUserCurrentUser?.user_id ? userBillingSettings : currentUserBillingSettings

  const [isPersonDataLoading, setIsPersonDataLoading] = useState(false)
  const [noCreditsModalOpened, setNoCreditsModalOpened] = useState(false)
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [isBackLinkConfirmModalShown, setIsBackLinkConfirmModalShown] = useState(false)
  const [isNoInstancesWarningOpen, setIsNoInstancesWarningOpen] = useState(false)

  const personDataTabs = [
    {
      key: 'general',
      label: t('patientDetails.general.tabName'),
      children: <GeneralTab
        isEditing={isEditing}
        hasDataChanged={hasDataChanged}
        setHasDataChanged={(changed) => setHasDataChanged(changed)}
        isBackLinkConfirmModalShown={isBackLinkConfirmModalShown}
        setIsBackLinkConfirmModalShown={(isOpen: boolean) => setIsBackLinkConfirmModalShown(isOpen)}
      />,
    },
    ...(isEditing && selectedPersonData?.is_get_sessions ? [{
      key: 'sessions',
      label: (
        <div className={classes.tabLabel}>
          {t('session_plural', {ns: 'app'})}
          <TabItemsCount count={sessionListTotalCount || 0}/>
        </div>
      ),
      children: <SessionsTab isEditing={isEditing} />,
    }] : []),
    ...(isEditing && selectedPersonData?.is_get_reports ? [{
      key: 'reports',
      label: (
        <div className={classes.tabLabel}>
          {t('report_plural', {ns: 'app'})}
          <TabItemsCount count={requestListTotalCount || 0}/>
        </div>
      ),
      children: <ReportsTab isEditing={isEditing} />,
    }] : [])
  ]

  useEffect(() => {
    //pathname example: /patients/edit/6
    const id = pathname.split('/patients/edit/')[1]
    if (isEditing && (selectedPersonData?.id !== +id || sessionList === null || requestList === null)) {
      setIsPersonDataLoading(true)
      Promise.all([
        ...(selectedPersonData?.id !== +id ? [dispatch(GetPersonByIdThunk(+id))] : []),
        ...(sessionList === null
          ? [dispatch(GetSessionsByPersonIdThunk({
            infiniteScroll: false,
            requestParams: {
              ...sessionListRequestParams,
              person_data_request: {
                ...sessionListRequestParams.person_data_request,
                person_id: +id,
              }
            }
          }))]
          : []
        ),
        ...(requestList === null 
          ? [dispatch(GetRequestListByPersonIdThunk({
            infiniteScroll: false,
            requestParams: {
              ...requestListRequestParams,
              request_data_request: {
                ...requestListRequestParams.request_data_request,
                person_id: +id,
              }
            }
          }))]
          : []
        )
      ]).then(() => setIsPersonDataLoading(false))
    }
    if (!!selectedPersonData?.id && selectedPersonData?.id !== +id) {
      dispatch(setPersonDetailsActiveTabKey('general'))
    }
    // eslint-disable-next-line
  }, [dispatch, selectedPersonData, isEditing, pathname, requestList, sessionList])

  useEffect(() => {
    return () => {
      dispatch(setSessionList(null))
      dispatch(setRequestList(null))
    }
  }, [dispatch])

  const onTabChange = (key: PersonDetailsActiveTabKeyType) => {
    dispatch(setRequestData(null))
    dispatch(setSessionData(null))
    dispatch(setPersonDetailsActiveTabKey(key))
  }

  useEffect(() => {
    if (!!superUserCurrentUser?.user_id) {
      dispatch(GetUserBillingSettingsThunk({
        userId: superUserCurrentUser?.user_id,
        isCurrentUser: false
      }))
    }
  }, [dispatch, userData, superUserCurrentUser])

  const onAddSession = async() => {
    if (!!selectedPersonData?.instance_list?.length) {
      navigate(`/patients/${+pathname.split('/patients/edit/')[1]}/sessions/add`)
    } else if (!selectedPersonData?.instance_list?.length && !isNoInstancesWarningOpen) {
      setIsNoInstancesWarningOpen(true)
    } else {
      dispatch(setRequestData(null))
      dispatch(setSessionData(null))
      !!isNoInstancesWarningOpen && setIsNoInstancesWarningOpen(false)
      return dispatch(setPersonDetailsActiveTabKey('general'))
    }
  }

  const getUserBalance = async() => {
    if (!!superUserCurrentUser?.user_id) {
      return dispatch(GetUserBillingSettingsThunk({
        userId: superUserCurrentUser?.user_id,
        isCurrentUser: false
      }))
    } else {
      return dispatch(GetUserBillingSettingsThunk({
        userId: userData?.user_id,
        isCurrentUser: true
      }))
    }
  }

  const onGenerateReport = async() => {
    const a = await getUserBalance()
    console.log('billingSettings', billingSettings, (a.payload as {billing: UserBillingSettingsType})?.billing?.credits_balance)
    if (((a.payload as {billing: UserBillingSettingsType})?.billing?.credits_balance || 0) < 1) {
      setNoCreditsModalOpened(true)
    } else {
      dispatch(setRequestData(null))
      navigate(`/patients/${pathname.split('/patients/edit/')[1]}/generate-report`)
    }
  }

  if (!!selectedPersonData?.user?.user_id && selectedPersonData?.user?.user_id !== userData?.user_id && selectedPersonData?.user?.user_id !== superUserCurrentUser?.user_id) {
    return <Navigate to='/patients' />
  }

  if ((isSuperUser && !isUser && !superUserCurrentUser?.user_id)) {
    return <SuperUserWarningMessage />
  }

  if (isPersonDataLoading) {
    return <Spin style={{width: '100%'}}/>
  }

  return (
    <div className={classes.wrapper}>
      <GoBackLink
        onClick={() => navigate('/patients')}
        text={t('patientDetails.backBtn')}
        showConfirmModal={hasDataChanged ? () => setIsBackLinkConfirmModalShown(true) : undefined}
      />
      <div className={classes.pageTitle}>
        <h1 className='pageTitle'>
          {isEditing ? selectedPersonData?.name : t('patientDetails.title')}
        </h1>
        <div className={classes.tools}>
          {selectedPersonData?.is_add_session &&
            <Button type='primary' onClick={onAddSession}>
              + {t('patientDetails.sessions.addSessionBtn')}
            </Button>
          }
          {!superUserCurrentUser?.user_id && isEditing &&
            <div
              onClick={onGenerateReport}
              className={classes.generateReport}
            >
              {t('patientDetails.generateReport')}
            </div>
          }
        </div>
      </div>
      <Tabs
        items={personDataTabs}
        activeKey={activeTabKey}
        onChange={(key) => onTabChange(key as PersonDetailsActiveTabKeyType)}
      />
      {noCreditsModalOpened &&
        <InfoModal
          title={t('warnings.noCreditsForReportGeneration.title', {ns: 'app'})}
          text={t('warnings.noCreditsForReportGeneration.description', {ns: 'app'})}
          closeModal={() => setNoCreditsModalOpened(false)}
        />
      }
      {/* no instances for a new session: warning */}
      <ConfirmationModal
        open={!!isNoInstancesWarningOpen}
        title={t('warnings.noInstancesForSession.title', {ns: 'app'})}
        description={t('warnings.noInstancesForSession.description', {ns: 'app'})}
        onClose={() => setIsNoInstancesWarningOpen(false)}
        onConfirm={onAddSession}
        type={'warning'}
        confirmText={t('actions.add', {ns: 'app'})}
      />
    </div>
  )
}

const TabItemsCount = ({count}: {count: number}) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="22" rx="11" fill="#E0EFFE"/>
      <text textAnchor="middle" style={{fontSize: '10px', fill: '#007DFA', strokeWidth: '0.5px'}} x="50%" y="65%">{count}</text>
    </svg>
  )
}

export default PersonForm
