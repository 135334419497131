import { Button, Modal } from 'antd'
import { ReactComponent as SuccessIcon } from './../../../img/icons/success.svg'
import classes from './NewUserHintModal.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setUserData } from '../../../store/currentUserReducer'
import { userDataHelper } from '../../../helpers/localStorageHelper'

interface NewUserHintModalPropTypes {
  isOpen: boolean
  // onClose: () => void
}

const NewUserHintModal: React.FC<NewUserHintModalPropTypes> = ({ isOpen }) => {
  const { t } = useTranslation('pages')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userData = useAppSelector(state => state.currentUser.userData)

  const updateFirstLogin = () => {
    dispatch(setUserData({...userData, is_first_login: false}))
    userDataHelper.setUserData({...userData, is_first_login: false})
  }

  const skipForNow = () => {
    updateFirstLogin()
  }

  const addPatient = () => {
    updateFirstLogin()
    navigate(`/patients/add`)
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      destroyOnClose
      closable={false}
      width={400}
      centered
    >
      <div className={classes.wrapper}>
        <SuccessIcon />
        <div className={classes.title}>
          {t('patientList.newUserHintModal.title')}
        </div>
        <div style={{textAlign: 'center'}}>
          {t('patientList.newUserHintModal.description')}
        </div>
        <div className={`${classes.actionButtons} actionButtons`}>
          <Button onClick={skipForNow}>
            {t('patientList.newUserHintModal.skip')}
          </Button>
          <Button type='primary' onClick={addPatient}>
            {t('patientList.newUserHintModal.add')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default NewUserHintModal
