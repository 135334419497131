import { useEffect, useState } from 'react'
import classes from './InstanceTab.module.css'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  GetInstanceListThunk,
  GetInstanceTopicListThunk,
  selectAddInstanceModalOpened,
  selectInstanceList,
  selectInstanceTopicList, setAddInstanceModalOpened
} from '../../../../store/instanceReducer'
import { Button, Form, Spin } from 'antd'
import RadioGroup from '../../../common/RadioGroup/RadioGroup'
import { InstanceType } from '../../../../types/instanceType'
import { GridRenderCellParams } from '@mui/x-data-grid'
import MuiTable from '../../../common/MuiTable/MuiTable'
import SubtopicModal from './SubtopicModal/SubtopicModal'
import { SessionFormDataType } from '../../../../types/sessionTypes'
import {
  EditPersonThunk, GetPersonByIdThunk,
  getPersonGeneralData,
  selectSelectedPersonData,
} from '../../../../store/personReducer'
import { orderBy, uniqBy } from 'lodash'
import infoModals from '../../../common/InfoModal/InfoModal.module.scss'
import TagMultiSelect from '../../../common/TagMultiSelect/TagMultiSelect'
import { NewPersonType, PersonType } from '../../../../types/personTypes'
import { useTranslation } from 'react-i18next'
import { selectUserData } from '../../../../store/currentUserReducer'

const InstanceTab: React.FC<InstanceTabPropTypes> = ({ sessionData, setSessionData, isEditing, setHasDataChanged }) => {
  const dispatch = useAppDispatch()
  const instanceList = useAppSelector(selectInstanceList)
  const instanceTopicList = useAppSelector(selectInstanceTopicList)
  const selectedPersonData = useAppSelector(selectSelectedPersonData)
  const [selectedInstanceId, setSelectedInstanceId] = useState(0)
  const [isTopicsLoading, setIsTopicsLoading] = useState(false)
  const [selectedTopicId, setSelectedTopicId] = useState(0)
  const isAddInstanceModalOpened = useAppSelector(selectAddInstanceModalOpened)

  useEffect(() => {
    if (selectedPersonData?.id) {
      dispatch(GetInstanceListThunk())
        .then(() => setSelectedInstanceId(getInstanceOptions()[0]?.value))
    }
    // eslint-disable-next-line
  }, [dispatch, selectedPersonData])

  useEffect(() => {
    if (!!selectedInstanceId && selectedInstanceId !== 0) {
      setIsTopicsLoading(true)
      dispatch(GetInstanceTopicListThunk(selectedInstanceId))
        .then(() => setIsTopicsLoading(false))
    }
  }, [dispatch, selectedInstanceId])

  const getInstanceOptions = () => {
    const patientInstances = selectedPersonData?.instance_list || []
    let instanceOptions = []
    if (!isEditing) {
      instanceOptions = patientInstances
    } else {
      const addedInstanceId = sessionData.sessionData
        ?.filter(item => !patientInstances.some(i => i.id === item.instance_id))
        ?.map(item => instanceList?.find(i => item.instance_id === i.id)!) || []
      instanceOptions = uniqBy(
        [
          ...patientInstances,
          ...addedInstanceId
        ], instance => instance?.id
      )
    }
    return orderBy(instanceOptions, instance => instance?.order_num)
      .map(instance => ({
        label: instance?.name,
        value: instance?.id,
        bgColor: instance?.color,
      }))
  }

  const selectTopic = (topicId: number) => {
    setSelectedTopicId(topicId)
  }

  const addDataToSession = (subtopics: { subtopic_id: number, subtopic_name: string, note: string }[]) => {
    let updatedSessionData = sessionData
    if (!subtopics?.length) {
      updatedSessionData = {
        ...sessionData,
        sessionData: sessionData?.sessionData.filter(s => s.topic_id !== selectedTopicId)
      }
    } else if (!!sessionData.sessionData.some(s => s.topic_id === selectedTopicId)) {
      updatedSessionData = {
        ...sessionData,
        sessionData: sessionData.sessionData.map(s => s.topic_id === selectedTopicId
          ? { ...s, subtopics }
          : s
        )
      }
    } else {
      updatedSessionData = {
        ...sessionData,
        sessionData: [
          ...sessionData.sessionData,
          {
            instance_id: selectedInstanceId,
            topic_id: selectedTopicId,
            subtopics
          }
        ]
      }
    }
    setSessionData(updatedSessionData)
    setSelectedTopicId(0)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Topic',
      flex: 1.5,
      minWidth: 120,
      sortable: false,
      filterable: false,
      cellClassName: `tableSecondaryTextStyle`,
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <div className={classes.topicColumn}>
          {params.row.name}
        </div>
      )
    },
    {
      field: 'subtopic_name',
      headerName: 'Subtopic',
      flex: 1,
      minWidth: 100,
      sortable: false,
      filterable: false,
      cellClassName: 'tableSecondaryTextStyle',
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <div className={classes.subtopicName}>
          {params.row.subtopics?.map((s: any) => (
            <div key={s.subtopic_id}>{s.subtopic_name}</div>
          ))}
        </div>
      )
    },
    {
      field: 'note',
      headerName: 'Comment',
      flex: 1,
      sortable: false,
      filterable: false,
      scrollX: 'auto',
      minWidth: 100,
      cellClassName: 'tableSecondaryTextStyle',
      renderCell: (params: GridRenderCellParams<any, Date>) => (
        <div className={classes.subtopicComment}>
          {params.row.subtopics?.map((s: any) => (
            <div key={s.subtopic_id}>{s.note}</div>
          ))}
        </div>
      )
    },
    // {
    //   field: 'Actions',
    //   headerName: '',
    //   sortable: false,
    //   filterable: false,
    //   flex: 0.5,
    //   minWidth: 80,
    //   renderCell: (params: GridRenderCellParams<any, Date>) => (
    //     <div className={classes.selectTopic} onClick={() => selectTopic(params.row.id)}>
    //       Select
    //     </div>
    //   )
    // },
  ]

  const getRows = () => {
    return instanceTopicList?.map(t => {
      const topicSelectedData = sessionData?.sessionData?.find(s => s.topic_id === t.id)
      return ({
        ...t,
        subtopics: topicSelectedData?.subtopics || [],
      })
    }) || []
  }

  if (instanceList === null || instanceTopicList === null) {
    return <Spin style={{ width: '100%' }} />
  }

  return (
    <div className={classes.wrapper}>
      <RadioGroup
        options={getInstanceOptions()}
        value={selectedInstanceId as number}
        onChange={(val) => setSelectedInstanceId(val as number)}
        activeStyles={{ fontSize: '16px', fontWeight: 600 }}
      >
        <button type={"button"} className={classes.addInstanceBtn} onClick={() => dispatch(setAddInstanceModalOpened(true))}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="plus">
              <path id="Icon" d="M10.0003 4.1665V15.8332M4.16699 9.99984H15.8337" stroke="#667085" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
          </svg>
          <span>Add Instance</span>
        </button>
      </RadioGroup>
      <MuiTable
        columns={columns}
        rows={getRows()}
        loading={isTopicsLoading}
        toolbar={() => <TableCustomHeader instance={instanceList?.find(i => i.id === selectedInstanceId)!} />}
        getRowHeight={(row) => {
          if (row.model.subtopics.length > 1) {
            return row.model.subtopics.length * 40
          } else {
            return 52
          }
        }}
        onCellClick={(clickVal: any) => {
          // if (clickVal.field === 'name') {
          selectTopic(clickVal.row.id)
          // }
        }}
      />
      {selectedTopicId !== 0 &&
        <SubtopicModal
          isOpen={true}
          onClose={() => setSelectedTopicId(0)}
          topic={getRows()?.find(t => t.id === selectedTopicId)!}
          addDataToSession={addDataToSession}
          setHasDataChanged={setHasDataChanged}
        />
      }
      {isAddInstanceModalOpened &&
        <AddInstanceModal isEditing={isEditing}/>
      }
    </div>
  )
}

const AddInstanceModal:React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const { t } = useTranslation(['forms', 'app'])
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const instanceList = useAppSelector(selectInstanceList)
  const selectedPersonData = useAppSelector(selectSelectedPersonData)

  const [isSaving, setIsSaving] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)

  const handleSave = (personData: NewPersonType) => {
    setIsSaving(true)
    dispatch(EditPersonThunk({
      personId: selectedPersonData?.id!,
      person: {
        ...getPersonGeneralData(selectedPersonData as PersonType),
        instance_list: [
          ...(selectedPersonData?.instance_list?.map(instance => Number(instance.id)) ?? []),
          ...(personData.instance_list?.map(instance => Number(instance)) ?? [])
        ],
        user_id: userData.user_id,
        is_active: selectedPersonData?.is_active
      }
    }))
      .then(() => {
        setIsSaving(false)
        dispatch(setAddInstanceModalOpened(false))
      })
      .then(() => {
        dispatch(GetPersonByIdThunk(selectedPersonData?.id!))
      })
  }

  return (
    <div className={infoModals.inner}>
      <div className={infoModals.inner__content} style={{
        height: 'fit-content',
        width: '440px'
      }}>
        <p className={classes.formTitle}>Add Instance for patient</p>
        <Form
          name='person'
          onFinish={handleSave}
          autoComplete='off'
          validateTrigger='onBlur'
          disabled={isEditing && !selectedPersonData?.is_active}
          style={{
            width: '100%'
          }}
          onValuesChange={() => setHasChanges(true)}
        >
          <label className={classes.formLabel}>Instance</label>
          <Form.Item
            name='instance_list'
            // rules={[{required: true, message: t('fields.instances.validationMessage')}]}
            style={{ width: '100%', marginBottom: 0 }}
          >
            <TagMultiSelect
              placeholder={'Instance'}
              options={instanceList
                ?.filter(inst => !selectedPersonData?.instance_list?.some(i => i.id === inst.id))
                ?.map(instance => ({
                  label: instance.name,
                  value: String(instance.id),
                  color: instance.color
                })) || []
              }
              getPopupContainer={() => document.getElementById('user-instances-select')!}
              tagColor='#3B414B'
            />
          </Form.Item>
          <p className={classes.formSelectDesc}>
            {/* eslint-disable-next-line */}
            The selected instances will be added to the patient's profile settings
          </p>
          <div className={`${classes.actionButtons} actionButtons`}>
            <Form.Item style={{
              width: '100%'
            }}>
              <Button onClick={() => dispatch(setAddInstanceModalOpened(false))} style={{
                width: '100%'
              }}>
                {t('actions.cancel', { ns: 'app' })}
              </Button>
            </Form.Item>

            {hasChanges &&
              <Form.Item style={{
                width: '100%'
              }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isSaving}
                  style={{
                    width: '100%'
                  }}
                >
                  {t('actions.save', { ns: 'app' })}
                </Button>
              </Form.Item>
            }
          </div>
        </Form>
      </div>
    </div>
  )
}

const TableCustomHeader: React.FC<{ instance: InstanceType }> = ({ instance }) => {

  return (
    <div className={classes.tableHeader}>
      <div className={classes.headerTitle}>
        {instance?.name}
      </div>
      <div className={classes.headerDescription}>
        {instance?.description}
      </div>
    </div>
  )
}

interface InstanceTabPropTypes {
  isEditing: boolean
  setSessionData: (data: SessionFormDataType) => void
  sessionData: SessionFormDataType
  setHasDataChanged: (hasChanged: boolean) => void
}

export default InstanceTab
